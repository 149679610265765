<template>
  <!-- 授权页面 -->
  <div v-loading="allPageLoading" class="authorize">
    <div class="authorize-top f-c">
      <div class="f-l authorize-top-btn" @click="goBack">
        <img
          src="@/assets/images/icon/navtop_back.png"
          class="authorize-top-btnicon"
        />
        <span>{{ $t("el.common.back") }}</span>
      </div>
      <div class="f-l authorize-top-title">
        {{ $t("el.home.teachingManage") }} >
        {{
          isAddPage
            ? $t("el.saas.addTeacher")
            : $t("el.infrastructure.authorizationTeachers")
        }}
      </div>
    </div>
    <div class="authorize-content">
      <!-- 基本信息 -->
      <div v-if="!isAddPage" class="authorize-content-item">
        <div class="authorize-content-item-title">
          {{ $t("el.infrastructure.userInformation") }}
        </div>
        <div class="authorize-content-item-box f-c">
          <div class="authorize-content-item-item f-c f-l">
            <div class="authorize-content-item-item-label">
              {{ $t("el.authDialog.name") }}{{ $t("el.symbol.colon") }}
            </div>
            <div class="authorize-content-item-item-text">
              {{ detailData.staffName }}
            </div>
          </div>
          <!-- <div class="authorize-content-item-item f-c f-l">
            <div class="authorize-content-item-item-label">
              {{ $t("el.schoolResourceManage.workNumber") }}
              {{ $t("el.symbol.colon") }}
            </div>
            <div class="authorize-content-item-item-text">
              {{ detailData.staffCode }}
            </div>
          </div> -->
          <div class="authorize-content-item-item f-c f-l">
            <div class="authorize-content-item-item-label">
              {{ $t("el.infrastructure.mobilePhone") }}
            </div>
            <div class="authorize-content-item-item-text">
              {{ detailData.mobile || $t("el.common.None") }}
            </div>
          </div>

          <div class="flex f-c f-l authorize-content-item-item">
            <div class="authorize-content-item-item-label">
              {{ $t("el.infrastructure.IdNumber") + $t("el.common.colon") }}
            </div>
            <div class="authorize-content-item-item-text">
              {{ detailData.idNumber || $t("el.common.None") }}
            </div>
          </div>
        </div>
      </div>
      <div v-else class="authorize-content-item" style="padding-bottom: 10px">
        <div class="authorize-content-item-title">
          {{ $t("el.saas.chooseUser") }}
        </div>
        <div
          class="authorize-content-item-item-label choose-label"
          style="padding-top: 11px"
        >
          {{ $t("el.saas.chooseMember") }}{{ $t("el.common.colon") }}
        </div>
        <el-select
          v-model="formData.userIds"
          :placeholder="$t('el.common.pleaseEnter')"
          multiple
          filterable
          remote
          :remote-method="remoteGetUser"
          style="margin-left: 13px"
        >
          <el-option
            v-for="item in remoteUserOptions"
            :key="item.id"
            :value="item.id"
            :label="item.staffName"
          >
            <span>{{ item.staffName }} {{ item.staffPhone }}</span>
          </el-option>
        </el-select>
      </div>

      <!-- tab切换 -->
      <el-tabs v-model="activeName">
        <el-tab-pane
          :label="$t('el.infrastructure.functionalAuthority')"
          name="first"
        >
          <div class="authorize-content-item">
            <div class="flex">
              <div
                class="authorize-content-item-item-label required"
                style="padding-top: 11px"
              >
                {{ $t("el.common.FunctionalRole") }}{{ $t("el.common.colon") }}
              </div>
              <div class="authorize-content-item-item">
                <div class="authorize-content-item-item-specific f-c">
                  <el-select
                    v-model="formData.roleIds"
                    :placeholder="$t('el.common.pleaseEnter')"
                    multiple
                    filterable
                    :disabled="~~detailData.isManager === 1 ? true : false"
                  >
                    <el-option
                      v-for="item in roleOptionsFilted"
                      :key="item.roleId"
                      :value="item.roleId"
                      :label="item.roleName"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>
            </div>
            <div class="flex">
              <div
                class="authorize-content-item-item-label"
                style="padding-top: 11px"
              >
                {{ $t("el.common.TeachingClass") + $t("el.common.colon") }}
              </div>
              <div class="authorize-content-item-item-specific f-c">
                <el-cascader
                  ref="classCascaderRef"
                  style="width: 280px"
                  class="authorize-content-item-item-text choose-text"
                  placeholder=""
                  :options="classOptions"
                  :show-all-levels="true"
                  filterable
                  clearable
                  :props="classOptionProps"
                  v-model="classCodeList"
                ></el-cascader>
              </div>
            </div>
            <div class="flex">
              <div
                class="authorize-content-item-item-label"
                style="padding-top: 10px"
              >
                {{ $t("el.common.resourceUse") }} ：
              </div>

              <div class="authorize-content-item-item">
                <div class="userPermission-main-item-item-radio f-c">
                  <div
                    class="userPermission-main-item-item-radio-item"
                    style="margin-left: 13px"
                  >
                    <el-checkbox
                      v-model="formData.upload"
                      :true-label="0"
                      :false-label="1"
                      >{{
                        $t("el.infrastructure.allowUploadPersonalResources")
                      }}</el-checkbox
                    >
                    <el-checkbox
                      v-model="formData.download"
                      :true-label="0"
                      :false-label="1"
                      >{{
                        $t("el.infrastructure.allowDownloadResources")
                      }}</el-checkbox
                    >
                    <el-checkbox
                      v-model="formData.copy"
                      :true-label="0"
                      :false-label="1"
                      >{{
                        $t("el.infrastructure.allowCopyResources")
                      }}</el-checkbox
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="functionalAuthority-btns">
              <el-button
                type="primary"
                :loading="loading"
                size="medium"
                style="width: 84px"
                @click="saveFunctionalPermission"
                >{{ $t("el.common.confirm") }}</el-button
              >
              <el-button size="medium" style="width: 84px" @click="goBack">{{
                $t("el.common.cancel")
              }}</el-button>
            </div>
          </div>
        </el-tab-pane>
        <!-- 课程权限 -->
        <el-tab-pane
          v-if="!isAddPage"
          :label="$t('el.infrastructure.coursePermissions')"
          name="second"
        >
          <div
            v-loading="allLoading"
            v-if="mode === 'show'"
            class="authorize-content-item"
          >
            <!-- <div
              class="authorize-content-item-title"
              style="margin-bottom: 8px"
            >
              {{ $t("el.infrastructure.coursePermissions") }}
            </div> -->
            <div
              style="
                display: flex;
                justify-content: space-between;
                align-items: center;
              "
            >
              <div class="flex authorize-content-btn">
                <el-button
                  class="plus"
                  type="primary"
                  size="small"
                  icon="el-icon-plus"
                  @click="onHandleAddCourse"
                  >{{ $t("el.common.add") }}</el-button
                >
                <el-button
                  @click="onHandleUpdateCourse"
                  class="update"
                  size="small"
                  icon="el-icon-edit"
                  >{{ $t("el.common.edit") }}</el-button
                >
              </div>
              <span class="back-to-saas" @click="onHandleTryEdit"
                >试用课程转正式</span
              >
            </div>
            <course-permission
              ref="coursePermission"
              v-if="showCoursePermission"
              @setAllLoading="(loading) => (allLoading = loading)"
              :staffCode="formData.staffCode"
              :type="coursePermissionType"
              :authCourseIds="formData.authCourseIds"
              :getGradeListData="getGradeListData"
              style="height: 500px"
            ></course-permission>

            <!-- <div class="authorize-btns">
              <el-button
                type="primary"
                :loading="loading"
                size="medium"
                style="width: 84px"
                @click="savePermission"
                >{{ $t("el.common.confirm") }}</el-button
              >
              <el-button size="medium" style="width: 84px" @click="goBack">{{
                $t("el.common.cancel")
              }}</el-button>
            </div> -->
          </div>
          <div
            v-loading="allLoading"
            v-else-if="mode === 'edit'"
            class="authorize-content-item"
          >
            <!-- 编辑模式 -->
            <div
              class="flex authorize-content-btn"
              style="align-items: baseline"
            >
              <span class="authorize-content-btn-text">{{
                $t("el.courseList.selectOperate")
              }}</span>
              <span class="authorize-content-btn-text1"
                >({{
                  $t("el.courseList.SelectedCourses") + $t("el.common.colon")
                }}
                {{ chosedAuthCourseIdsLength }})</span
              >
            </div>
            <course-permission
              ref="coursePermissionEdit"
              v-if="showCoursePermissionEdit"
              @setAllLoading="(loading) => (allLoading = loading)"
              :staffCode="formData.staffCode"
              :type="coursePermissionType"
              :authCourseIds="[]"
              :getGradeListData="getGradeListData"
              @get-max-authorize-date="getMaxAuthorizeDate"
              style="height: 500px"
            ></course-permission>
            <div class="flex flex-c flex-m btn-edit">
              <el-button
                class="plus"
                type="primary"
                size="small"
                @click="onEditExpiresDate"
                >{{ $t("el.common.ModifyExpiration") }}</el-button
              >
              <el-button
                @click="onRemoveCourse"
                type="danger"
                plain
                size="small"
              >
                {{ $t("el.common.RemoveCourse") }}
              </el-button>
              <el-button @click="onCancelEdit" plain size="small">{{
                $t("el.common.CancelEditing")
              }}</el-button>
            </div>
          </div>
          <div
            v-loading="allLoading"
            v-else-if="mode === 'tryEdit'"
            class="authorize-content-item"
          >
            <!-- 试用转正式编辑模式 -->

            <div
              class="flex authorize-content-btn"
              style="align-items: baseline"
            >
              <span class="authorize-content-btn-text">{{
                $t("el.courseList.selectOperate")
              }}</span>
              <span class="authorize-content-btn-text1"
                >({{
                  $t("el.courseList.SelectedCourses") + $t("el.common.colon")
                }}
                {{ chosedAuthCourseIdsLength }})</span
              >
            </div>

            <course-permission
              ref="coursePermissionEdit"
              v-if="showCoursePermissionEdit"
              @setAllLoading="(loading) => (allLoading = loading)"
              :staffCode="formData.staffCode"
              :type="coursePermissionType"
              :authCourseIds="[]"
              :getGradeListData="getGradeListData"
              @get-max-authorize-date="getMaxAuthorizeDate"
              :isTry="mode === 'tryEdit'"
              style="height: 500px"
            ></course-permission>

            <!-- <div class="flex flex-c flex-m btn-edit">
              <el-button
                class="plus"
                type="primary"
                size="small"
                @click="$refs.BtnAuthorizeDateRef4.onHandleAddCourseSubmit()"
                >转为正式课程</el-button
              >

              <el-button @click="onCancelTryEdit" plain size="small"
                >取消</el-button
              >
            </div> -->
            <btn-authorize-date
              style="position: relative; margin-top: 12px"
              ref="BtnAuthorizeDateRef4"
              :showOnlyTrial="false"
              :expirationDate="expirationDate"
              :submitText="'转为正式课程'"
              :maxAuthorizeDateItem="maxAuthorizeDateItem"
              :defaultTimeValue="3"
              :chosedAuthCourseIdsLength="chosedAuthCourseIdsLength"
              @setExpirationDate="setExpirationDate"
              @onHandleAddCourseSubmit="
                (...defaultArg) => onHandleAddCourseSubmit(4, ...defaultArg)
              "
              @closeDialogCourse="onCancelTryEdit"
              @onHandleUseDate="onHandleUseDate"
              @onCustomDate="expirationDate = ''"
            ></btn-authorize-date>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <c-dialog
      ref="dialogEditCourse"
      :visible="showEditExpiresDialog"
      :optionsData="optionsEditData"
      @close-dialog="closeEditExpiresDialog"
      :formOptions="{
        hiddenDefaultFormBtn: true,
      }"
      :hideForm="true"
    >
      <div slot="customView" class="custom-edit-content">
        <btn-authorize-date
          :expirationDate="expirationDate"
          :maxAuthorizeDateItem="maxAuthorizeDateItem"
          :submitText="$t('el.common.confirm')"
          :chosedAuthCourseIdsLength="chosedAuthCourseIdsLength"
          @setExpirationDate="setExpirationDate"
          @onHandleAddCourseSubmit="
            (...defaultArg) => onHandleAddCourseSubmit(2, ...defaultArg)
          "
          @closeDialogCourse="closeEditDialogCourse"
          @onHandleUseDate="onHandleUseDate"
          @onCustomDate="expirationDate = ''"
        ></btn-authorize-date>
      </div>
    </c-dialog>
    <!-- 添加授权课程弹窗 -->
    <c-dialog
      ref="dialogCourse"
      :visible="showDialogCourse"
      :optionsData="optionsData"
      @close-dialog="closeDialogCourse"
      class="course-dialog"
      :formOptions="{
        hiddenDefaultFormBtn: true,
      }"
      :hideForm="true"
    >
      <div v-loading="allLoading" slot="customView" class="custom-content">
        <div class="flex flex-m" style="margin-bottom: 10px; padding-top: 16px">
          <span class="title">{{ $t("el.common.add") }}</span>
          <div class="course">
            {{ $t("el.courseList.SelectedCourses") + $t("el.common.colon") }}
            <span>{{ chosedAuthCourseIdsLength }}</span>
          </div>
          <span class="desc">{{
            $t("el.courseList.MaximumAuthorizedDate")
          }}</span>
        </div>
        <course-permission
          ref="addCoursePermissionRef"
          v-if="showDialogCourse"
          @setAllLoading="(loading) => (allLoading = loading)"
          :staffCode="formData.userStaffCode"
          :type="'add'"
          :authCourseIds="formData.authCourseIds"
          :getGradeListData="getAddGradeListData"
          @get-max-authorize-date="getMaxAuthorizeDate"
        ></course-permission>
        <btn-authorize-date
          v-if="showDialogCourse"
          :expirationDate="expirationDate"
          :maxAuthorizeDateItem="maxAuthorizeDateItem"
          :submitText="$t('el.common.add')"
          :chosedAuthCourseIdsLength="chosedAuthCourseIdsLength"
          @setExpirationDate="setExpirationDate"
          @onHandleAddCourseSubmit="
            (...defaultArg) => onHandleAddCourseSubmit(1, ...defaultArg)
          "
          @closeDialogCourse="closeDialogCourse"
          @onHandleUseDate="onHandleUseDate"
          @onCustomDate="expirationDate = ''"
        >
          <template #appendContent>
            <div style="position: relative">
              <el-checkbox
                style="position: absolute; top: -26px; right: -220px"
                v-model="formData.isTry"
                :true-label="1"
                :false-label="0"
                >仅试用（仅可使用前三节课）</el-checkbox
              >
            </div>
          </template></btn-authorize-date
        >
      </div>
    </c-dialog>
    <!-- 授权提醒弹窗 -->
    <el-dialog
      width="464px"
      class="authorizeDialog"
      center
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :modal-append-to-body="false"
      :visible.sync="authorizeVisible"
    >
      <div slot="title" class="red authorizeDialog-header">
        {{ $t("el.common.AuthorizationRemind") }}
      </div>
      <div class="authorizeDialog-content">
        <div class="authorizeDialog-content-text1">
          {{ $t("el.common.DelegateTeacher") }}
        </div>
        <div class="authorizeDialog-content-text2">
          {{ $t("el.common.ProtectIntellectualProperty") }}
        </div>
      </div>
      <span slot="footer" class="authorizeDialog-footer">
        <el-button
          type="primary"
          size="medium"
          style="width: 84px; margin: 0 18px 0"
          :loading="loading"
          @click="saveSure"
          >{{ $t("el.common.confirm") }}</el-button
        >
        <el-button
          size="medium"
          style="width: 84px; margin: 0 18px 0"
          @click="authorizeVisible = false"
          >{{ $t("el.common.cancel") }}</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CoursePermission from "./components/CoursePermissionNew.vue";
import BtnAuthorizeDate from "./components/BtnAuthorizeDate.vue";
import {
  authDetail,
  saveAuth,
  schoolTree,
  classTree,
  editBatchUserAuthCopy,
  editBatchUserAuthCopyNew,
  updateCourseFormal,
  // detailForIdNumberAndMobile,
} from "@/api/home/teachmanage";
import normalRadioIcon from "@/assets/images/icon/radio_normal@2x.png";
import selectedRadioIcon from "@/assets/images/icon/radio_selected@2x.png";
import normalCheckboxIcon from "@/assets/images/icon/checkbox-normal.png";
import selectedCheckboxIcon from "@/assets/images/icon/checkbox-selected.png";
import { handparams } from "../../../utils";
import CDialog from "@/components/biz-components/CDialog/index.vue";
import { getRemoteUserListApi, getRoleListApi } from "@/api/manage/role-manage";
import { courseAuthId, courseAuthIdToObj } from "@/utils/auth";

export default {
  name: "authorize",
  // 注册组件
  components: {
    CoursePermission,
    CDialog,
    BtnAuthorizeDate,
  },
  // 定义数据
  data() {
    return {
      normalRadioIcon: normalRadioIcon,
      selectedRadioIcon: selectedRadioIcon,
      normalCheckboxIcon: normalCheckboxIcon,
      selectedCheckboxIcon: selectedCheckboxIcon,
      allLoading: false,
      activeName: "first",
      mode: "show",
      coursePermissionType: "default", // 授课组件类型 default: 默认  add: 添加 edit: 编辑
      showDialogCourse: false, // 是否显示添加课程弹窗
      expirationDate: "", // 到期时间
      maxAuthorizeDateItem: {},
      optionsData: {},
      optionsEditData: {},

      loading: false, // 按钮加载中
      schoolOptions: [], // 学校选项
      showCoursePermission: false,
      showCoursePermissionEdit: false,
      showEditExpiresDialog: false, // 修改过期时间弹框
      allPageLoading: false,
      classOptions: [], // 授课班级选项
      classOptionProps: {
        value: "code",
        label: "name",
        children: "children",
        multiple: true,
      },
      optionProps: {
        value: "schoolCode",
        label: "schoolName",
        children: "children",
      }, // 选项props
      formData: {
        // 提交信息
        staffCode: this.$route.query.id,
        upload: 0,
        // reviewAuth: "",
        download: 0,
        copy: 0,
        manageClassList: [],
        roleIds: [], // 角色
        userIds: [], // 用户
        isTry: 0,
      },
      initInfo: {
        eduAuth: "",
        schoolCodeList: [],
      },
      classCodeList: [], // 授课班级选中
      schoolCodeList: [],
      authorizeVisible: false, // 授权确认提醒弹窗
      detailData: {},
      subOptions: [],
      gradOptions: [],
      currentSchoolCode: null, // 当前用户的学校
      currentSchoolName: null,
      chosedAuthCourseIdsLength: 0, // 选中的课程数目
      remoteUserOptions: [], // 远程搜索的用户下拉列表
      roleOptions: [], // 角色列表下拉
    };
  },
  // 计算属性
  computed: {
    ...mapGetters([
      "eduAuth",
      "getGradeListData",
      "getAddGradeListData",
      "staffCode",
    ]),

    chooseItem() {
      return {
        function: [
          {
            name: this.$t("el.infrastructure.educationalAdministrator"),
            check: 1,
          },
          {
            name: this.$t("el.infrastructure.averageUser"),
            check: "",
          },
        ],
        isOPen: [
          {
            name: this.$t("el.common.open"),
            check: 1,
          },
          {
            name: this.$t("el.schoolResourceManage.shutDown"),
            check: 0,
          },
        ],
      };
    },

    // 当前是否是新增教职工页面
    isAddPage() {
      return !this.$route.query.id;
    },
    roleOptionsFilted() {
      if (this.roleOptions.length) {
        const superRoleId = this.roleOptions.find((i) => i.isSuper).roleId;

        if (!this.formData.roleIds.some((i) => i === superRoleId)) {
          return this.roleOptions.filter((i) => !i.isSuper);
        }
      }
      return this.roleOptions;
    },
  },
  // 创建完成钩子
  created() {
    this.initData();
  },
  // 方法定义
  methods: {
    ...mapActions(["getGradeList"]),
    async initData() {
      if (!this.isAddPage) {
        this.getData();
      }
      this.isAddPage && this.remoteGetUser();
      // this.getSchoolTree();
      this.getClassTree();
      this.handleGetRoleList(); // 获取角色下拉列表
    },
    async getData() {
      // 详情信息
      await authDetail({ userId: this.$route.query.id })
        .then(({ data }) => {
          this.detailData = data;

          if (data.manageClassList && data.manageClassList.length > 0) {
            let classCodeList = [];
            this.formData.manageClassList = data.manageClassList;
            this.formData.manageClassList.forEach((element) => {
              let a = [element.gradeId + "", element.classId + ""];
              classCodeList.push(a);
            });
            this.classCodeList = classCodeList;
          } else {
            this.classCodeList = [];
          }
          this.formData.upload = data.upload;
          this.formData.download = data.download;
          this.formData.copy = data.copy;

          this.formData.roleIds = data.roleIds || [];
          this.formData.userIds = [this.$route.query.id];

          this.formData.authCourseIds = (data.authOriginCourseIds || []).map(
            (item) => {
              item.courseId = item.authCourseId;
              item._uniqueId = courseAuthId(item); // 拼接唯一id
              return item._uniqueId;
            }
          );
        })
        .catch(() => {});
      this.showCoursePermission = true;
    },
    // 设置最大到期时间-使用
    onHandleUseDate() {
      console.log(1234, "this.maxAuthorizeDateItem", this.maxAuthorizeDateItem);
      if (this.maxAuthorizeDateItem.isEditMode) {
        this.expirationDate = this.maxAuthorizeDateItem.maxExpiresDate;
      } else {
        this.expirationDate =
          this.maxAuthorizeDateItem.expiresType === 0
            ? this.maxAuthorizeDateItem.expiresDateDescribe
            : this.maxAuthorizeDateItem.expiresDate;
      }
    },
    getSchoolTree() {
      // 获取学校选项
      schoolTree().then(({ data }) => {
        this.schoolOptions = data;
      });
    },
    // 获取授课班级选项
    getClassTree(data) {
      return classTree(data)
        .then(({ data }) => {
          // 需要只取前两层，到班级
          this.classOptions = (data || []).map((first) => {
            if (first.children?.length) {
              first.children = first.children.map((second) => {
                Reflect.deleteProperty(second, "children");
                return second;
              });
            }
            return first;
          });
          // this.currentSchoolCode = data[0].code;
          // this.currentSchoolName = data[0].name;
        })
        .catch(() => {
          this.currentSchoolCode = "";
          // this.currentSchoolName = "";
          this.classOptions = [];
        });
    },
    saveFunctionalPermission() {
      // 保存功能权限
      this.savePermission();
    },
    // 获取最大到期时间的 item 项
    getMaxAuthorizeDate(minItem, length, isEditMode) {
      if (minItem) {
        minItem.isEditMode = isEditMode;
        this.maxAuthorizeDateItem = minItem;
        this.chosedAuthCourseIdsLength = length;
      } else {
        this.maxAuthorizeDateItem = {
          isEditMode: isEditMode,
        };
      }
    },
    // 最大过期时间
    setExpirationDate(expirationDate) {
      this.expirationDate = expirationDate;
    },
    // 移除课程
    onRemoveCourse() {
      let authCourseIds =
        this.$refs["coursePermissionEdit"] &&
        this.$refs["coursePermissionEdit"].getExtraAuthCourseIds();
      if (authCourseIds.length === 0) {
        console.log(1111111);
        this.$message.warning(this.$t("el.planCourse.pleaseSelectCourse"));
        return;
      }
      this.$alert(
        this.$t("el.courseList.sureSelectedCourse", [
          this.chosedAuthCourseIdsLength,
        ]),
        this.$t("el.common.reminder"),
        {
          center: true,
          type: "warning",
          confirmButtonText: this.$t("el.common.confirm"),
        }
      ).then(() => {
        this.onHandleAddCourseSubmit(3, this.maxAuthorizeDateItem.expiresDate);
      });
    },
    // 添加课程权限提交 1新增 2编辑
    onHandleAddCourseSubmit(type, value) {
      let authCourseIds = [];
      this.allLoading = true;
      if (type === 2 || type === 3 || type === 4) {
        authCourseIds =
          this.$refs["coursePermissionEdit"] &&
          this.$refs["coursePermissionEdit"].getExtraAuthCourseIds();
      } else {
        authCourseIds =
          this.$refs["addCoursePermissionRef"] &&
          this.$refs["addCoursePermissionRef"].getExtraAuthCourseIds();
      }

      if (authCourseIds.length === 0) {
        console.log(222222);
        this.$message.warning(this.$t("el.planCourse.pleaseSelectCourse"));
        this.allLoading = false;
        return;
      }

      let queryParams = {
        userIds: [this.formData.staffCode],
        userId: this.formData.staffCode,
        authOriginCourseIds: authCourseIds.map((item) =>
          courseAuthIdToObj(item)
        ),

        expiresType: value !== "永久" ? 1 : 0, // 必填-到期类型 0：永久，1自定义
        expiresDate: value, // 不必填 -到期时间
        type: type === 2 ? "updBatch" : type === 3 ? "deleteBatch" : "addBatch",
        isTry: this.formData.isTry,
      };

      if (type === 4) {
        queryParams.authCourseTenantIds = queryParams.authOriginCourseIds.map(
          (item) => item.tenantInfoId
        );

        delete queryParams.type;
        delete queryParams.isTry;
        // queryParams.tenantType = this.tenantType;
        // queryParams.availableTimePeriodsIds = time;

        this.allLoading = true;
        updateCourseFormal(handparams(queryParams))
          .then((result) => {
            // if (result.success) {
            this.$message.success("试用课程转正式成功");
            this.closeDialogCourse(true);
            // }
          })
          .catch(() => {
            //
          })
          .finally(() => (this.allLoading = false));
      } else {
        editBatchUserAuthCopyNew(handparams(queryParams))
          .then((result) => {
            if (result.success) {
              if (type === 2) {
                this.$message.success(this.$t("el.classManage.editSuccess"));
                this.closeEditDialogCourse(true);
              } else if (type === 3) {
                this.$message.success(this.$t("el.classManage.deleteSuccess"));
                this.closeEditDialogCourse(true);
              } else {
                this.$message.success(this.$t("el.classManage.addSuccess"));
                this.closeDialogCourse(true);
              }
            }
            this.allLoading = false;
          })
          .catch(() => {
            //
            this.allLoading = false;
          });
      }
    },
    // 添加课程权限弹框
    onHandleAddCourse() {
      this.optionsData = {
        // dialogTitle: this.$t("el.courseDetails.teachingLessons"),
        "show-close": false,
        width: "90%",
      };
      this.expirationDate = "";
      this.maxAuthorizeDateItem = {};
      this.formData.userStaffCode = this.$route.query.id;
      this.showDialogCourse = true;
      this.formData.isTry = 0;
    },
    // 关闭过期时间弹框
    closeEditExpiresDialog() {
      this.showEditExpiresDialog = false;
    },
    closeEditDialogCourse(refresh) {
      this.showEditExpiresDialog = false;

      if (refresh) {
        this.initData();
        this.coursePermissionType = "default";
        this.chosedAuthCourseIdsLength = 0;
        // 退出编辑模式
        this.onCancelEdit();
      }
    },
    // 关闭添加课程弹窗
    closeDialogCourse(refresh) {
      this.showDialogCourse = false;
      this.coursePermissionType = "default";
      this.chosedAuthCourseIdsLength = 0;
      this.expirationDate = "";
      this.maxAuthorizeDateItem = {};
      if (refresh) {
        this.initData();
        // 退出编辑模式
        this.onCancelEdit();
        this.$nextTick(() => {
          this.$refs["coursePermission"].toGetCourseAuthList();
        });
      }
    },
    // 编辑课程权限
    onHandleUpdateCourse() {
      // 编辑模式
      this.mode = "edit";
      this.showCoursePermission = false;
      this.coursePermissionType = "edit";
      this.chosedAuthCourseIdsLength = 0;
      this.$nextTick(() => {
        this.showCoursePermissionEdit = true;
      });
    },
    // 取消编辑
    onCancelEdit() {
      this.mode = "show";
      this.showCoursePermissionEdit = false;
      this.coursePermissionType = "default";
      this.chosedAuthCourseIdsLength = 0;
      this.$nextTick(() => {
        this.showCoursePermission = true;
      });
    },
    onEditExpiresDate() {
      if (this.chosedAuthCourseIdsLength === 0) {
        console.log(333333);
        this.$message.warning(this.$t("el.planCourse.pleaseSelectCourse"));
        return;
      }
      this.optionsEditData = {
        dialogTitle: this.$t("el.courseList.ModifyExpirationTime"),
      };
      this.expirationDate = "";

      this.showEditExpiresDialog = true;
    },
    // 原保存权限
    savePermission() {
      let dataParams = handparams(this.formData);

      // 转化授课班级数据，判断 子教务和普通用户
      // if (~~dataParams.eduAuth === 1 || !dataParams.eduAuth) {
      if (this.classCodeList.length > 0) {
        let classNodes = this.$refs["classCascaderRef"].getCheckedNodes();
        let manageClassList = [];
        classNodes.forEach((element) => {
          if (element.level === 2) {
            let a = {
              classId: element.value,
              className: element.label,
              gradeId: element.parent.value,
              gradeName: element.parent.label,
              schoolCode: this.currentSchoolCode, // 当前所属的学校
              // schoolName: this.currentSchoolName,
            };
            manageClassList.push(a);
          }
        });
        dataParams.manageClassList = manageClassList;
      } else {
        dataParams.manageClassList = [];
      }

      if (!dataParams.userIds?.length) {
        this.$message.error(this.$t("el.saas.pleaseChooseMember"));
        return;
      }
      if (!dataParams.roleIds?.length) {
        this.$message.error(this.$t("el.saas.pleaseChooseRole"));
        return;
      }
      Reflect.deleteProperty(dataParams, "authCourseIds");
      this.saveSure(dataParams);
    },
    saveSure(dataParams) {
      this.loading = true;
      saveAuth(dataParams)
        .then(({ data }) => {
          this.$message({
            type: "success",
            message: this.$t("el.common.saveSuccessfully"),
          });
          this.authorizeVisible = false;
          this.loading = false;
          this.goBack();
        })
        .catch(() => {
          this.authorizeVisible = false;
          this.loading = false;
        });
    },
    goBack() {
      this.$router.go(-1);
    },
    getChildTree(data, allCheckedData) {
      // 转换树形结构
      var arr = [];
      arr = this.$utils.treeDataChangeField(data, {
        data: {
          id: function (key, value, item) {
            return {
              check: allCheckedData.some(function (v) {
                if (v.subjectId) {
                  return v.subjectId == value;
                } else {
                  return v.gradeId == value;
                }
              }),
              id: value,
            };
          },
        },
      });

      return arr;
    },
    getId(arr, name) {
      // 保存获取一选择id
      var idArr = [];
      arr.forEach((item, index, arr) => {
        if (item.check) {
          idArr.push({ [name]: item.id });
        }
      });
      return idArr;
    },
    authorizeChoose(checkbox) {
      // 功能权限选择
      this.formData.eduAuth = checkbox.check;
      // 子管理员不允许修改学校
      if (this.formData.eduAuth == 1) {
        this.schoolCodeList = this.initInfo.schoolCodeList;
      }
    },
    authorizeReviewAuthChoose(checkbox) {
      // 功能权限选择
      this.formData.reviewAuth = checkbox.check;
    },

    // 远程搜索用户 TODO: 没有远程搜索
    remoteGetUser(v) {
      getRemoteUserListApi().then((res) => {
        this.remoteUserOptions = res?.data || [];
      });
    },
    // 获取所有角色下拉
    handleGetRoleList() {
      getRoleListApi().then((res) => {
        this.roleOptions = res?.data || [];
      });
    },
    onHandleTryEdit() {
      this.mode = "tryEdit";
      this.showCoursePermission = false;
      this.coursePermissionType = "tryEdit";
      this.chosedAuthCourseIdsLength = 0;
      this.$nextTick(() => {
        this.showCoursePermissionEdit = true;
      });
    },
    // 取消试用转正式
    onCancelTryEdit() {
      this.mode = "show";
      this.showCoursePermissionEdit = false;
      this.coursePermissionType = "default";
      this.chosedAuthCourseIdsLength = 0;
      this.$nextTick(() => {
        this.showCoursePermission = true;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.authorize {
  background: #fff;
  padding: 18px 16px 56px;
  border-radius: 4px;
  &-top {
    height: 32px;
    line-height: 32px;
    &-btn {
      width: 90px;
      height: 32px;
      background: rgba(245, 246, 250, 1);
      border-radius: 12px;
      text-align: center;
      margin-right: 28px;
      font-size: 13px;
      // font-family: "MicrosoftYaHei";
      color: rgba(68, 68, 68, 1);
      cursor: pointer;
      img {
        width: 12px;
        margin-right: 4px;
      }
    }
    &-text {
      font-size: 14px;
      // font-family: "MicrosoftYaHei";
      color: rgba(0, 0, 0, 0.85);
    }
  }
  &-content {
    font-size: 14px;
    // font-family: "MicrosoftYaHei";
    padding: 21px 45px 0;
    // 添加编辑按钮
    &-btn {
      font-size: 12px;
      font-family: PingFang SC;
      font-weight: 400;
      margin-bottom: 12px;

      &-text {
        width: fit-content;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #333333;
      }
      &-text1 {
        width: fit-content;
        height: fit-content;
        font-size: 12px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #666666;
      }
      .plus {
        width: fit-content;
        box-sizing: border-box;
        padding: 8px 12px;
        background: #6049fe;
        color: #ffffff;
        box-shadow: 0px 4px 5px 0px rgba(96, 73, 254, 0.19);
        border-radius: 20px;
        font-size: 12px;
      }
      .update {
        width: fit-content;
        box-sizing: border-box;
        padding: 8px 12px;
        color: #5a53f5;
        border: 2px solid #5a53f5;
        border-radius: 20px;
        font-size: 12px;
      }
      span {
        margin-left: 7px;
      }
    }
    & > div:last-of-type {
      border-bottom: 0;
    }
    &-item {
      border-bottom: 1px solid #e9e9e9;
      padding-bottom: 0px;
      // 课程组件区域高度
      /deep/ .checkbox-group-content {
        height: calc(100% - 50px);
        overflow: auto;
      }
      // 编辑模式 按钮区域
      .btn-edit {
        position: fixed;
        bottom: 80px;
        left: calc(50% + 100px);
        text-align: center;
        transform: translateX(-50%);
        z-index: 1;

        /deep/ .el-button--small {
          border-radius: 20px;
        }
      }
      .functionalAuthority-btns {
        text-align: center;
        margin-top: 20px;
        margin-bottom: 20px;
        /deep/ .el-button--medium {
          border-radius: 20px;
        }
      }
      &-title {
        // color: rgba(100, 108, 149, 1);
        padding: 18px 0 10px;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 600;
        color: rgba(48, 49, 51, 1);
      }
      &-item {
        line-height: 34px;
        margin-bottom: 4px;
        &-label {
          float: left;
          // color: rgba(121, 123, 127, 1);
          // min-width: 75px;
          // text-align: right;
          margin-left: 53px;
          flex-shrink: 0;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #666666;
          &.required {
            position: relative;
            &::before {
              position: absolute;
              font-size: 4px;
              left: -10px;
              top: 10px;
              content: "*";
              transform: scale(0.8);
              color: #f56c6c;
            }
          }
        }
        &-text {
          float: left;
          color: rgba(48, 49, 51, 1);
          margin-right: 20px;
        }
        &-checkbox {
          margin-right: 24px;
          display: flex;
          align-items: center;
          cursor: pointer;
          line-height: 40px;
          img {
            width: 16px;
            margin-right: 6px;
          }
          span {
            color: rgba(48, 49, 51, 1);
          }
        }
        &-specific {
          margin-left: 13px;
          & > div {
            margin-right: 66px;
          }
        }
        &-label.choose-label {
          position: relative;
          &::before {
            position: absolute;
            font-size: 4px;
            left: -10px;
            top: 10px;
            content: "*";
            transform: scale(0.8);
            color: #f56c6c;
          }
        }
        &.flex {
          margin: 0 53px;
          &.dashed {
            border-bottom: 1px dashed #e9e9e9;
            margin-bottom: 14px;
            padding-bottom: 14px;
          }
          .authorize-content-item-item-label {
            margin-left: 0;
          }
        }
      }
      &-choose {
        margin-top: 10px;
        .choose-label {
          color: #464646;
          vertical-align: middle;
          line-height: 40px;
          margin-left: 13px;
        }
        .choose-text {
          vertical-align: middle;
          /deep/ .el-input__inner {
            height: 32px;
            border-radius: 4px;
            border: 1px solid rgba(0, 0, 0, 0.15);
          }
        }
      }
      &-tip {
        font-size: 12px;
        color: #b3b3b3;
        margin-top: 6px;
        &:before {
          width: 157px;
          content: "";
          // display: inline-block;
          padding-left: 13px;
        }
      }
    }
    // 新增用户
    &-add {
      background: #000;
    }
  }
  &-btns {
    text-align: center;
    /* margin-top: 30px; */
    position: fixed;
    bottom: 50px;
    z-index: 1;
    left: 50%;
    transform: translateX(-50%);
    button {
      margin: 0 18px 0;
    }
  }
  /deep/ .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #5a53f5;
    border-color: #5a53f5;
  }
  /deep/ .el-checkbox__input.is-checked .el-checkbox__inner {
    background-color: #5a53f5;
    border-color: #5a53f5;
  }
  /deep/ .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #5a53f5;
  }
  /deep/ .el-tabs__item.is-active {
    color: #6049ff !important;
    background: #ffffff !important;
  }
  /deep/ .el-tabs__item {
    font-size: 16px;
  }
  /deep/ .el-tabs__active-bar {
    position: absolute;
    bottom: 0;
    left: auto;
    height: 2px;
    background-color: #6049ff !important;
    z-index: 1;
    -webkit-transition: -webkit-transform 0.3s
      cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
      -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    list-style: none;
  }
  /deep/ .el-tabs__nav-wrap::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background-color: transparent;
    z-index: 1;
  }

  .course-dialog {
    .custom-content {
      text-align: left;
      height: 90vh;

      .btn-content {
        position: absolute;
        bottom: 0;
        left: 50%;
        height: 126px;
        transform: translateX(-50%);
        /deep/ .btn-content-date {
          margin: 5px auto !important;
        }
      }
      .proper-question {
        color: #ffffff;
      }
      .title {
        width: fit-content;
        min-width: 40px;
        font-size: 20px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #333333;
        margin-left: 40px;
      }
      .course {
        width: fit-content;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #666666;
        margin-left: 24px;
      }
      .desc {
        width: fit-content;

        font-size: 12px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #999999;
        margin-left: auto;
        margin-right: 28px;
      }
      .form-btn {
        width: fit-content;
        margin-left: auto;
        margin-right: auto;
      }
      /deep/ .permission-container {
        border: 0 solid #dcdddd;
        height: calc(100% - 190px);
        box-sizing: border-box;
      }
      /deep/ .checkbox-group-content {
        height: calc(100% - 40px);
        overflow: auto;
      }
    }
    /deep/ .el-dialog__header {
      display: none;
    }
    /deep/ .el-dialog__body {
      padding: 0 0;
    }
  }
  .custom-edit-content {
    /deep/ .btn-content {
      position: relative;
      bottom: 0;
      left: 0;
      height: 146px;
      transform: none;
    }
  }
  .back-to-saas {
    font-size: 14px;
    cursor: pointer;
    padding: 0 8px;
    color: @themeNewPurple;
  }
}
.authorizeDialog {
  &-header {
    font-size: 18px;
    // font-family: "MicrosoftYaHei-Bold", "MicrosoftYaHei";
    font-weight: bold;
    color: rgba(48, 49, 51, 1);
    line-height: 24px;
  }
  &-content {
    text-align: center;
    padding: 4px 0 31px;
    &-text1 {
      font-size: 19px;
      // font-family: "MicrosoftYaHei";
      color: rgba(100, 108, 149, 1);
      line-height: 37px;
    }
    &-text2 {
      font-size: 14px;
      // font-family: MicrosoftYaHei;
      color: rgba(176, 176, 176, 1);
      line-height: 19px;
      margin-top: 14px;
    }
  }
  /deep/ .el-dialog__footer {
    padding-bottom: 30px;
  }
  button {
    width: 84px;
    height: 36px;
    border-radius: 5px;
  }
}
</style>
